import React from 'react'

import Blockoption from './Blockoption'

import './skills.css'


const Skills = () => {
  return (
      <>
      
    <div className='heading'>
      <h5>Compétences</h5>
      <h2>Skills</h2>
    </div>
    <div className='skills__container'>
    <Blockoption/>

    </div>
    
    </>
  )
}

export default Skills