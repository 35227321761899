import React from 'react'
import './services.css'

function Services() {
  return (
    <>
    <section className=''>Services</section>
    <iframe className='threed' src='https://my.spline.design/hands3duicopy-1f2cbfd5f97365a8f92e4d2bbdf11a4b/' frameborder='0' width='100%' height='100%'></iframe>
    </>
  )
}

export default Services